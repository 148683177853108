import styled, { createGlobalStyle } from 'styled-components';
import Play from './assets/fonts/Play-Bold.ttf';
import OpenSansCondensed from './assets/fonts/OpenSansCondensed-Bold.ttf';
import OverpassRegular from './assets/fonts/Overpass-Regular.ttf';
import RobotoThin from './assets/fonts/Roboto-Thin.ttf';

export const theme = {
  dark: {
    primary: '#0c0b10',
    secondary: '#232228',
    accent: '#f15562',
    text: '#FFFFFF',
    border: '#7A7A7D',
    error: '#CE3535',
  },
  light: {
    primary: '#FFFFFF',
    secondary: '#D9D9D9',
    accent: '#f15562',
    text: '#000000',
    border: '#7A7A7D',
    error: '#CE3535',
  },
  // primary, secondary, accent, text
};

export default createGlobalStyle`
   @font-face {
        font-family: "Header";
        font-weight: 700;
        font-style: bold;
        src: url(${Play});
    }
    @font-face {
      font-family: "Sub Header";
        font-weight: 700;
        font-style: bold;
        src: url(${OpenSansCondensed});
    }
    @font-face {
      font-family: "Paragraph";
        font-weight: 300;
        src: url(${OverpassRegular});
    }
    @font-face {
      font-family: "Thin";
        font-weight: 100;
        src: url(${RobotoThin});
    }
  
  
  `;

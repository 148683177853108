import React, { useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';

const EbayAuth = props => {
  const dispatch = useDispatch();
  const dashboard_state = useSelector(state => state.dashboard_state);

  const { CREATE_JSON, CREATE_ARRAY } = bindActionCreators(
    actionCreators,
    dispatch
  );
  useMemo(() => {
    const auth_url = window.location.href;
    console.log(auth_url);
    console.log(window.location);
    CREATE_JSON('ebay_auth', auth_url);
  }, []);

  return (
    <>
      <Redirect to="/dashboard" />
    </>
  );
};

export default EbayAuth;

import axios from 'axios';
import { o_decode, o_email } from './ObfuscateLocalStorage';
import store from '../index.js';
import { CREATE_JSON } from '../state/actions';

/**
 *
 * @param {JSON} options The axios options parameters needed to perform an eBay api call
 * @returns {JSON} response from the eBay api call
 */
const AuthRefreshv2 = async options => {
  let state = store.getState();
  if (
    Date.now() > state.dashboard_state.access_expiry ||
    state.dashboard_state.access_expiry == 0
  ) {
    const payload = `{"email": "${o_decode(localStorage.getItem(o_email))}"}`;
    const res = await axios({
      baseURL: state.dashboard_state.server_url,
      url: '/api/ebay/refresh_auth',
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      data: payload,
    })
      .then(async response => {
        options.data.auth = response.data.access_token;
        store.dispatch(CREATE_JSON('access_token', response.data.access_token));
        store.dispatch(CREATE_JSON('access_expiry', Date.now() + 7000 * 1000));
        const funct_response = await axios(options)
          .then(response => {
            return response;
          })
          .catch(error => {
            return error;
          });
        return funct_response;
      })
      .catch(error => {
        return error;
      });
    return res.data;
  } else {
    const funct_response = await axios(options)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
    return funct_response.data;
  }
};

export default AuthRefreshv2;

export const o_jwt = 'hasiodb12p';
export const o_email = '0v38y08a9un';
export const o_ebay_client_id = '1d2maslg5brte';
export const o_ebay_redirect_uri = '9mpox21edrt';
export const o_policy_ids = 'ce2iudhqnk';
export const o_ali_email = '0xpjncuy';
export const o_ali_pass = 'f76ncnmnmfddlewhtr';

export const o_decode = data => {
  let b64 = Buffer.from(data, 'base64').toString();

  let decoded_data = b64;

  return decoded_data;
};

export const o_encode = data => {
  let b64 = Buffer.from(data).toString('base64');

  // TODO: Increase encryption
  let encoded_data = b64;

  return encoded_data;
};

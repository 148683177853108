import React, { useState, lazy, Suspense, useMemo, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from './state/index';
import GlobalFonts, { theme } from './App.style';
import { makeStyles } from '@material-ui/core/styles'; //TODO: Remove material ui
import axios from 'axios';
import {
  o_jwt,
  o_decode,
  o_encode,
  o_ebay_client_id,
  o_ebay_redirect_uri,
  o_policy_ids,
} from './util/ObfuscateLocalStorage';

import LandingPage from './pages/LandingPage';
import EbayAuth from './pages/EbayAuth';
import AuthRefreshv2 from './util/AuthRefreshv2';

const SignUp = lazy(() => import('./pages/SignUp/SignUp'));
const Dashboard = lazy(() => import('./pages/Dashboard/index.js'));
const SignIn = lazy(() => import('./pages/SignIn/SignIn.js'));
const Dev = lazy(() => import('./pages/Dev.js'));
const ProtectedRoute = lazy(() => import('./util/ProtectedRoute.js'));

const styles = makeStyles(theme => ({
  /* Scrollbar */
  '@global': {
    '*::-webkit-scrollbar-track': {
      border: '1px solid black',
      backgroundColor: '#0c0b10',
    },

    '*::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#0c0b10',
    },

    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#f15562',
      borderRadius: '8px',
    },
  },
}));

export const App = props => {
  const dashboard_state = useSelector(state => state.dashboard_state);
  const dispatch = useDispatch();
  const { CREATE_JSON } = bindActionCreators(actionCreators, dispatch);

  const allow =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxNDI2ZmE1YjBlMjZmNTBiNTY2ZTRkMSIsImVtYWlsIjoia2V2dnZpbnJlZWRAZ21haWwuY29tIiwiaWF0IjoxNjMxNzQ2NTk3LCJleHAiOjE2NjMzMDM1MjN9.tUcrhGrSsoAEJGboXNYSF-aPUfW8UhXY2Teegm2riuw';
  const disallow =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxNDI2ZmE1YjBlMjZmNTBiNTY2ZTRkMSIsImVtYWlsIjoia2V2dnZpbnJlZWRAZ21haWwuY29tIiwiaWF0IjoxNjMxNzQ2ODEwLCJleHAiOjE1NjMzMDM3MzZ9.sxyug5PuzBQMLbNyFH0vjMwnlYZLlKd_2Nze6aiMIoA';
  const initialSession = {
    jwt: `Bearer ${disallow}`,
    // serverURI: 'https://googlecompute.ddns.net',
    // serverURI: 'http://192.168.254.197:14501/',
  };

  // Initialize session and theme in redux
  useMemo(() => {
    // Initialize ebay tokens
    localStorage.setItem(
      o_ebay_client_id,
      o_encode('KevinRee-MoneyPri-PRD-b60b6dcaa-4cffe72c')
    );
    localStorage.setItem(
      o_ebay_redirect_uri,
      o_encode('Kevin_Reed-KevinRee-MoneyP-kgnfkbbix')
    );

    try {
      const prev_token = o_decode(localStorage.getItem(o_jwt));
      initialSession.jwt = prev_token;
    } catch (TypeError) {
      // Prevent throwing an error if this has not been set yet
    }

    try {
      const policy_ids = o_decode(localStorage.getItem(o_policy_ids));
      console.log(policy_ids);
    } catch (TypeError) {
      // Prevent throwing an error if this has not been set yet
      localStorage.setItem(o_policy_ids, o_encode('{}'));
    }

    // ACCESS_TOKEN,
    // marketplace_id,
    // payment_name,
    // international_fulfillment_name,
    // domestic_fulfillment_name,
    // return_name
  }, []);

  const [session, setSession] = useState(initialSession);

  return (
    <ThemeProvider theme={dashboard_state.dark_mode ? theme.dark : theme.light}>
      <GlobalFonts />
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route path="/" exact strict>
              <LandingPage />
              {/* <Redirect to="/dashboard" /> */}
            </Route>
            <Route path="/sign-in" exact strict>
              <SignIn
                appProps={{ session }}
                setSession={setSession}
                serverURI={session.serverURI}
              />
            </Route>
            <Route path="/sign-up" exact strict>
              <SignUp serverURI={session.serverURI} />
            </Route>
            <Route path="/dev" exact strict>
              <Dev serverURI={session.serverURI} />
            </Route>

            <ProtectedRoute
              path="/dashboard"
              exact
              strict
              setSession={setSession}
              appProps={{ session }}
              component={Dashboard}
            />
            <ProtectedRoute
              path="/ebay-auth"
              exact
              strict
              component={EbayAuth}
              setSession={setSession}
              appProps={{ session }}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

import React from 'react';
import { useHistory } from 'react-router';
import { ButtonC } from '../Primitives';
import {
  LandingPageContainer,
  Header,
  CenteredNav,
  HeaderText,
  SubHeaderText,
  ParagraphText,
  Hero,
  ButtonBar,
  ButtonWrapper,
  LamboClipArt,
} from './LandingPage.style';

const LandingPage = props => {
  const history = useHistory();
  return (
    <>
      <LandingPageContainer className="landing-page-container">
        <Hero />
        <Header className="header">
          <CenteredNav>
            {/* <ButtonC
              size="large"
              innerHTML={'START FOR FREE'}
              onClick={() => history.push('/sign-up')}
            />
            <ButtonC
              transparent={true}
              innerHTML={'Sign In'}
              onClick={() => history.push('/sign-in')}
            /> */}
          </CenteredNav>
        </Header>
        <HeaderText>Big Money Little Effort</HeaderText>
        <ParagraphText>
          {`Why spend time fulfilling orders when you could be spending more time driving 
          your Lamborghini? `}
        </ParagraphText>
        <ButtonBar className="button-bar">
          <ButtonWrapper className="button-wrapper">
            <ButtonC
              className="button"
              size="large"
              innerHTML={'Get Started'}
              onClick={() => history.push('/sign-up')}
            />
          </ButtonWrapper>

          <ButtonWrapper className="button-wrapper">
            <ButtonC
              className="button"
              size="large"
              innerHTML={'Sign In'}
              style={{ backgroundColor: 'transparent' }}
              onClick={() => history.push('/sign-in')}
            />
          </ButtonWrapper>
        </ButtonBar>
        <LamboClipArt className="lambo" />
        {/* <ParagraphText>
        {`Why would you want to spend time manually fulfilling orders when you could
          be spending more time driving your Lamborghini? With our all-in-one dropshipping
          automation platform, you can easily achieve a, "set it and forget it" income stream.
          Best of all you don't need to enter any payment information until you make your 
          first 3 sales. What are you waiting for?`}
        </ParagraphText> */}
      </LandingPageContainer>
    </>
  );
};

export default LandingPage;

export const example_increment = () => {
  return dispatch => {
    dispatch({
      type: 'increment',
    });
  };
};

export const example_decrement = () => {
  return dispatch => {
    dispatch({
      type: 'decrement',
    });
  };
};

export const example_payload = amount => {
  return dispatch => {
    dispatch({
      type: 'example_payload',
      payload: amount,
    });
  };
};

/**
 * Below are for DashboardReducer.js
 *
 */
export const REMOVE_INDEX = (key, index) => {
  return dispatch => {
    dispatch({
      type: 'REMOVE_INDEX',
      key: key,
      index: index,
    });
  };
};

export const PUSH_DATA = (key, value) => {
  return dispatch => {
    dispatch({
      type: 'PUSH_DATA',
      key: key,
      value: value,
    });
  };
};

export const CREATE_ARRAY = (key, array) => {
  return dispatch => {
    dispatch({
      type: 'CREATE_ARRAY',
      key: key,
      array: array,
    });
  };
};

export const CREATE_JSON = (key, json) => {
  return dispatch => {
    dispatch({
      type: 'CREATE_JSON',
      key: key,
      json: json,
    });
  };
};

export const ADD_TO_JSON = (key, key_2, value) => {
  return dispatch => {
    dispatch({
      type: 'ADD_TO_JSON',
      key: key,
      key_2: key_2,
      value: value,
    });
  };
};

export const DELETE_KEY_FROM_NESTED_JSON = (key, key_2) => {
  return dispatch => {
    dispatch({
      type: 'DELETE_KEY_FROM_NESTED_JSON',
      key: key,
      key_2: key_2,
    });
  };
};

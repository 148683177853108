const initial_state = {
  /**
   * Constants
   */
  server_url: 'https://googlecompute.ddns.net',
  /**
   * Dashboard
   */
  dash_view: 'Home',
  page_change: 0,
  dark_mode: true,
  /**
   * Ebay API
   */
  access_token: '',
  access_expiry: 0,
  /**
   * Import products
   */
  required_aspects: {},
  shipping_menu: '',
  aliexpress_url_form: '',
  scrape_flag: true,
  popup: false,
  state_counter: 0,
  click_order_tracker: [],
  image_array: [],
  variation_category: [],
  price_update: 0,
  profit_margin: 25,
  margin_type: { percent: true },
  round_menu: 0,
  click_counter: 0,
  margin_amount: 0,
  sale_price: 0,
  product_cost: 0,
  variation_name: 0,
  selected_variations: {},
  variation_thumbnail: {},
  product_data: {},
  published_products: {},
  logged_in: false,
};

const DashboardReducer = (state = initial_state, action) => {
  switch (action.type) {
    // Remove a key:value pair from inside a JSON object
    case 'REMOVE_FROM_JSON':
      return {
        // ...state,
        // [action.key]: { ...state[action.key], [action.key_2]: action.value },
      };

    // Update a value of some key_2 inside a JSON object
    case 'ADD_TO_JSON':
      return {
        ...state,
        [action.key]: { ...state[action.key], [action.key_2]: action.value },
      };

    // Create a JSON object with some key
    case 'CREATE_JSON':
      return {
        ...state,
        [action.key]: action.json,
      };

    case 'DELETE_KEY_FROM_NESTED_JSON':
      let result = { ...state };
      delete result[action.key][action.key_2];
      return result;

    case 'CREATE_ARRAY':
      return {
        ...state,
        [action.key]: action.array,
      };
    // Remove a value at some index from an array
    case 'REMOVE_INDEX':
      return {
        ...state,
        [action.key]: [
          ...state[action.key].slice(0, action.index),
          ...state[action.key].slice(action.index + 1),
        ],
      };

    // Push a value to an array
    case 'PUSH_DATA':
      return {
        ...state,
        [action.key]: [...state[action.key], action.value],
      };
    default:
      return state;
  }
};

export default DashboardReducer;

import styled from 'styled-components';
import Lambo from '../../assets/lambo.png';

export const LandingPageContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 5vh;
  padding-bottom: 2vh;
`;

export const CenteredNav = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: 70%;
  height: 70%;
  div {
    margin: 10px;
  }
`;

export const Hero = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.primary};
`;

export const HeaderText = styled.div`
  position: absolute;
  left: 15vw;
  top: 18vh;
  font-family: 'Header';
  font-size: 3rem;
  color: ${props => props.theme.text};
`;

export const SubHeaderText = styled.div`
  position: absolute;
  left: 15vw;
  top: 24.5vh;
  font-family: 'Sub Header';
  font-size: 2rem;
  color: ${props => props.theme.text};
`;

export const ParagraphText = styled.div`
  position: absolute;
  left: 15vw;
  top: 25vh;
  white-space: pre-line;
  font-family: 'Paragraph';
  text-indent: 20px;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.text};
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  left: 15vw;
  top: 25vh;
  width: 27%;
`;

export const ButtonWrapper = styled.div`
  width: 40%;
  padding: 1vw;
`;

export const LamboClipArt = styled.div`
  position: relative;
  left: 30vw;
  top: 30vh;
  width: 792px;
  height: 391px;
  background-image: url(${Lambo});
`;

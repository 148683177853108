import styled from 'styled-components';
import { theme } from '../../App.style';
import React, { useEffect } from 'react';

/**
 * Text field
 */
// const FieldContainer = styled.div`
//   display: flex;
//   position: relative;
//   flex-direction: column;
//   justify-content: left;
// `;

// const Label = styled.label`
//   font-size: 18px;
//   line-height: 1.25;
//   text-align: left;
//   color: ${props => props.theme.text};
//   position: relative;
//   margin-bottom: 6px;
// `;

// const InputField = styled.input`
//   height: 36px;
//   width: 100%;
//   box-sizing: border-box;
//   border-radius: 4px;
//   background-color: ${props => props.theme.secondary};
//   border: 1px solid ${props => props.theme.border};
//   color: ${props => props.theme.text};
//   padding-left: 10px;

//   &::placeholder {
//     color: ${props => props.theme.text};
//     opacity: 0.8;
//   }
// `;

// export const TextFieldC = ({ label, placeholder, type }) => {
//   return (
//     <>
//       <FieldContainer>
//         <Label>{label}</Label>
//         <InputField placeholder={placeholder} type={type}></InputField>
//       </FieldContainer>
//     </>
//   );
// };

const LargeButtonStyle = styled.div`
  font-family: 'Header';
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 4vh;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.border};

  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.text};

  cursor: pointer;
  user-select: none;

  transition: all 0.5s ease;

  &:hover {
    background: ${props => props.theme.text};
    color: ${props => props.theme.primary};
  }
`;

const MediumButtonStyle = styled.div`
  font-family: 'Header';
  display: flex;
  justify-content: center;
  align-items: center;

  width: 66%;
  height: 4vh;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.border};

  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.text};

  user-select: none;
  cursor: pointer;

  transition: all 1s ease;

  &:hover {
    background: ${props => props.theme.text};
    color: ${props => props.theme.primary};
  }
`;

const SmallButtonStyle = styled.div`
  font-family: 'Header';
  display: flex;
  justify-content: center;
  align-items: center;

  width: 33%;
  height: 4vh;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.border};

  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.text};

  user-select: none;
  cursor: pointer;

  transition: all 1s ease;

  &:hover {
    background: ${props => props.theme.text};
    color: ${props => props.theme.primary};
  }
`;

export const ButtonC = ({ innerHTML, onClick, size, ...otherProps }) => {
  if (size == 'large') {
    return (
      <>
        <LargeButtonStyle className="button" onClick={onClick} {...otherProps}>
          {innerHTML}
        </LargeButtonStyle>
      </>
    );
  } else if (size == 'medium') {
    return (
      <>
        <MediumButtonStyle className="button" onClick={onClick} {...otherProps}>
          {innerHTML}
        </MediumButtonStyle>
      </>
    );
  } else {
    return (
      <>
        <SmallButtonStyle className="button" onClick={onClick} {...otherProps}>
          {innerHTML}
        </SmallButtonStyle>
      </>
    );
  }
};

const InputContainer = styled.div`
  font-family: 'Thin';
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  position: sticky;

  & > input {
    border: 1px solid
      ${props => (props.error ? props.theme.error : props.theme.text)};
    border-radius: 0.25rem;
    background-color: transparent;
    outline: none;
    color: ${props => props.theme.text};
    padding: 12px 3px 12px 15px;
    font-size: 16px;
    transition: all 0.2s ease;
    z-index: 10;
  }
  & > label {
    color: ${props => props.theme.text};

    position: absolute;
    top: 12px;
    left: 15px;
    transition: all 0.2s ease;
    z-index: ${props => props.zindex};
    background-color: ${props => props.theme.primary};
    border-radius: 3px;

    ${props =>
      props.focused &&
      `
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 11;
      padding: 0 8px;
    `}
  }
`;

/**
 * A Plaid-inspired custom input component
 * https://dev.to/jastornaut/plaid-inspired-inputs-with-react-hooks-and-styled-components-1h93
 *
 * @param {string} value - the value of the controlled input
 * @param {string} type - the type of input we'll deal with
 * @param {string} label - the label used to designate info on how to fill out the input
 * @param {function} onChange - function called when the input value changes
 * @param {function} onFocus - function called when the input is focused
 * @param {function} onBlur - function called when the input loses focus
 * @param {function} setRef - function used to add this input as a ref for a parent component
 */
export const InputFieldC = ({
  value,
  type,
  label,
  onChange,
  onFocus,
  onBlur,
  setRef,
  maxlength,
  ...props
}) => {
  const [focused, setFocused] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleOnFocus = e => {
    validateInput(e.target.value);
    setFocused(true);
    onFocus();
  };

  const handleOnBlur = () => {
    setFocused(false);
    onBlur();
  };

  const validateInput = val => {
    if (val.length > maxlength) {
      setError(`Max character limit ${val.length}/${maxlength}`);
    } else {
      setError(null);
    }
  };

  const handleOnChange = e => {
    validateInput(e.target.value);
    onChange(e);
  };

  const renderLabel = () => {
    if (label) {
      if (error) {
        return <label>{`(${label}) ${error}`}</label>;
      }
      return <label>{label}</label>;
    }
    return null;
  };

  let isFocused = focused || String(value).length || type === 'date';

  return (
    <InputContainer focused={isFocused} error={error}>
      {renderLabel()}
      <input
        value={value}
        type={type}
        onChange={e => handleOnChange(e)}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        ref={ref => setRef(ref)}
        {...props}
      />
    </InputContainer>
  );
};

InputFieldC.defaultProps = {
  type: 'text',
  label: '',
  onChange: text => {
    console.error(`Missing onChange prop: ${text}`);
  },
  onFocus: () => {},
  onBlur: () => {},
  setRef: () => {},
};

const MenuSelectStyles = styled.div`
  padding-bottom: 20px;
  font-family: 'Thin';
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  min-width: 165px;

  position: relative;

  & > span {
    min-width: 100%;
    border-bottom: 1px solid ${props => props.theme.text};
    background-color: transparent;
    outline: none;
    color: ${props => props.theme.text};
    padding: 12px 3px 12px 15px;
    font-size: 16px;

    cursor: pointer;

    :hover {
      border-bottom: 1px solid ${props => props.theme.accent};
    }
    ${props => props.focused || `visibility: hidden;`}
  }
  & > label {
    color: ${props => props.theme.text};

    position: absolute;
    display: none;
    top: 36px;
    left: ${props => `${props.offset}px`};
    transition: all 0.2s ease;
    background-color: ${props => props.theme.primary};

    ${props =>
      props.focused &&
      `
      display: block;
      font-size: 13px;
      padding: 0 8px;
    `}
  }
`;

const MenuItem = styled.div`
  position: sticky;
  text-align: center;
  z-index: 1000;

  background-color: ${props => props.theme.primary};
  width: 80%;
  margin-left: 10%;
  line-height: 25px;
  height: 30px;
  background-color: ${props => props.theme.primary};
  border-bottom: 1px solid ${props => props.theme.border};
  color: ${props => props.theme.text};
  padding-top: 8px;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.accent};
  }
`;

const LastMenuItem = styled.div`
  position: sticky;
  text-align: center;
  z-index: 1000;

  width: 80%;
  margin-left: 10%;
  line-height: 25px;
  height: 30px;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.accent};
  }
`;

const MenuContainer = styled.div`
  z-index: 999;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: ${props => props.theme.primary};

  min-width: 180px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 3px;
`;

export const MenuSelectC = ({
  value,
  type,
  label,
  onChange,
  setRef,
  options,
  maxlength,
  offset,
  zindex,
  ...props
}) => {
  const [selected, setSelected] = React.useState(null);
  const [focused, setFocused] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (selected == null) {
      setSelected(options[0]);
    }
  }, []);

  const validateInput = val => {
    if (val.length > maxlength) {
      setError(`Max character limit ${val.length}/${maxlength}`);
    } else {
      setError(null);
    }
  };

  const toggleMenu = () => {
    setFocused(!focused);
  };

  const renderLabel = () => {
    if (label) {
      if (error) {
        return <label>{`(${label}) ${error}`}</label>;
      }
      return <label>{label}</label>;
    }
    return null;
  };

  const populateItems = options => {
    let items = [];
    for (let index in options) {
      if (index == options.length - 1) {
        items.push(
          <LastMenuItem
            key={options[index]}
            onClick={() => {
              setSelected(options[index]);
              setFocused(!focused);
              onChange(options[index]);
            }}
          >
            {options[index]}
          </LastMenuItem>
        );
      } else {
        items.push(
          <MenuItem
            key={options[index]}
            onClick={() => {
              setSelected(options[index]);
              setFocused(!focused);
              onChange(options[index]);
            }}
          >
            {options[index]}
          </MenuItem>
        );
      }
    }
    return items;
  };

  let isFocused = focused;

  return (
    <>
      <MenuSelectStyles focused={isFocused} offset={offset} zindex={zindex}>
        {renderLabel()}
        <span
          type={type}
          ref={ref => setRef(ref)}
          onClick={toggleMenu}
          value={selected}
          {...props}
        >
          {selected}
        </span>

        {focused || <MenuContainer>{populateItems(options)}</MenuContainer>}
      </MenuSelectStyles>
    </>
  );
};

MenuSelectC.defaultProps = {
  type: 'text',
  offset: 0,
  label: '',
  onChange: text => {
    console.error(`Missing onChange prop: ${text}`);
  },
  onBlur: () => {},
  onFocus: () => {},
  setRef: () => {},
};
